<template>
  <div class="community">
    <div class="info">
      <div class="top">
        <img :src="info.avatar" />
        <div class="content">
          <ul>
            <li>
              <span>{{ info.community_num || 0 }}</span>
              <br />动态
            </li>
            <li>
              <span>{{ info.care_num || 0 }}</span>
              <br />粉丝
            </li>
            <li>
              <span>{{ info.praise_num || 0 }}</span>
              <br />获赞
            </li>
          </ul>

          <mt-button
            class="btn-bg"
            size="small"
            v-if="id == uid"
            @click="myFind"
            style="color: white"
          >发布发现</mt-button>
          <mt-button
            v-else
            :type="info.is_care == 1 ? 'default' : 'primary'"
            size="small"
            @click="care(info.is_care == 1 ? 0 : 1)"
          >{{ info.is_care == 1 ? "已关注" : "关注" }}</mt-button>
        </div>
      </div>
      <p>简介：{{ info.desc }}</p>
    </div>

    <div class="scroll-container" ref="container">
      <div v-if="communityLsit.length > 0">
        <ul class="lesson-list">
          <li class="container" v-for="(item, index) in communityLsit" :key="index">
            <div class="title">
              <router-link
                :to="{
                  path: `/community/teacher/${item.uid}`,
                  query: {name: item.nickname}
                }"
              >
                <img :src="item.avatar" />
                <p>
                  <strong>{{ item.nickname }}</strong>
                  <span>{{ item.create_time }}</span>
                </p>
              </router-link>
              <div class="share" v-show="id == uid" @click="Delete(item.id)">删除</div>
            </div>
            <div class="content">
              <router-link :to="`/community/detail/${item.id}`">
                <p
                  ref="textContent"
                  :style="{ 'max-height': item.isShow ? textHeight : '' }"
                  v-html="item.content"
                ></p>
              </router-link>
              <span v-if="item.isShow" @click="item.isShow = false">
                <em>...</em>
                <br />全文
                <i class="jym-icon-test58"></i>
              </span>
              <span v-if="item.isShow == false" @click="item.isShow = true">
                收起
                <i class="jym-icon-test56"></i>
              </span>

              <div v-if="item.type == 1 && item.media.length">
                <div v-if="item.media.length == 1" class="figure-list one">
                  <img
                    v-for="(src, i) in item.media"
                    :key="i"
                    @click="ImagePreview(item.media, i)"
                    v-lazy="src"
                  />
                </div>
                <div
                  v-else-if="item.media.length % 2 == 0 && item.media.length < 6"
                  class="figure-list two"
                >
                  <div class="img-container">
                    <img
                      v-for="(src, i) in item.media"
                      :key="i"
                      @click="ImagePreview(item.media, i)"
                      v-lazy="src"
                    />
                  </div>
                </div>
                <div v-else class="figure-list three">
                  <div class="img-container">
                    <img
                      v-for="(src, i) in item.media"
                      :key="i"
                      @click="ImagePreview(item.media, i)"
                      v-lazy="src"
                    />
                  </div>
                </div>
              </div>
              <div class="video" v-if="item.type == 2">
                <video :src="item.media" width="100%" height="100%" controls></video>
              </div>
            </div>

            <div class="bottom">
              <span @click="praise(item.is_praise == 0 ? 1 : 0, item.id, index)">
                <i :class="item.is_praise == 1 ? 'jym-dianzan3 orange' : 'jym-dianzan4'"></i>
                {{ item.praise_num == 0 ? '点赞' : item.praise_num}}
              </span>
              <span>
                <router-link :to="`/community/detail/${item.id}`">
                  <i class="jym-pinglun1"></i>
                  {{ item.comment_num > 0 ? item.comment_num : '评论' }}
                </router-link>
              </span>
              <span @click="collect(item.is_collect == 0 ? 1 : 0, item.id, index)">
                <i :class="item.is_collect == 1 ? 'jym-shoucang1 orange' : 'jym-shoucang'"></i>
                {{ item.collect_num == 0 ? '收藏' : item.collect_num }}
              </span>
            </div>
          </li>
        </ul>
      </div>
      <div v-else class="emptyData" v-show="initLoading">
        <img src="@assets/images/emptyData.png" />
        <p>暂无数据</p>
      </div>
    </div>

    <van-image-preview
      v-if="imageShow"
      v-model="imageShow"
      :images="images"
      :showIndex="false"
      :showIndicators="true"
      :startPosition="startPosition"
      @close="vanClose"
    ></van-image-preview>

    <Loading :loaded="loadend" :loading="loading"></Loading>
  </div>
</template>

<script>
import { MessageBox } from "mint-ui";
import Loading from "@components/Loading";
import { getUser } from "@api/user";
import {
  getCommunityList,
  communityPerson,
  getCommunityCare,
  communityCollect,
  communityPraise,
  communityDelete,
} from "@api/community";

export default {
  name: "community-list",
  components: {
    Loading,
  },
  data() {
    return {
      id: "",
      uid: "",
      page: 1,
      limit: 10,
      info: {},
      communityLsit: [],
      textHeight: null,
      loading: false,
      loadend: false,
      imageShow: false,
      images: [],
      initLoading: false,
      startPosition: 0,
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.getUser();
    this.getHeaderInfo();
  },
  mounted() {
    this.loadMore();
    this.$scroll(this.$refs.container, () => {
      !this.loading && this.loadMore();
    });
    window.onresize = () => {
      this.communityLsit.forEach((ele, index) => {
        this.$set(
          this.communityLsit,
          index,
          Object.assign({}, ele, { isShow: null })
        );
      });
      setTimeout(() => {
        this.calculateText();
      }, 0);
    };
  },
  methods: {
    // 关闭图片预览
    vanClose() {
      this.images = [];
      this.imageShow = false;
    },

    // 图片预览
    ImagePreview(images, index) {
      this.images = images;
      this.imageShow = true;
      this.startPosition = index;
    },

    // 获取用户信息
    getUser() {
      let that = this;
      getUser().then((res) => {
        that.uid = res.data.uid;
      });
    },

    // 获取主页
    getHeaderInfo() {
      let that = this;

      communityPerson(that.id).then((res) => {
        that.$set(that, "info", res.data);
        that.$set(that, "is_collect", res.data.is_collect);
      });
    },

    //关注 status 1关注，0取关
    care(status) {
      let that = this;

      getCommunityCare({
        uid: that.id,
        status: status,
      })
        .then((res) => {
          that.$dialog.success(res.msg);
          that.$set(that.info, "is_care", that.info.is_care == 1 ? 0 : 1);
          that.$set(
            that.info,
            "care_num",
            that.info.is_care == 1 ? ++that.info.care_num : --that.info.care_num
          );
        })
        .catch((res) => {
          that.$dialog.error(res.msg);
        });
    },

    // 点赞
    praise(status, id, index) {
      communityPraise({
        status: status,
        community_id: id,
      })
        .then(() => {
          this.$set(this.communityLsit[index], "is_praise", status);
          this.$set(
            this.communityLsit[index],
            "praise_num",
            status == 0
              ? --this.communityLsit[index].praise_num
              : ++this.communityLsit[index].praise_num
          );
        })
        .catch((err) => {
          this.$dialog.error(err.msg);
        });
    },

    // 收藏
    collect(status, id, index) {
      communityCollect({
        status: status,
        community_id: id,
      })
        .then(() => {
          this.$set(this.communityLsit[index], "is_collect", status);
          this.$set(
            this.communityLsit[index],
            "collect_num",
            status == 0
              ? --this.communityLsit[index].collect_num
              : ++this.communityLsit[index].collect_num
          );
        })
        .catch((err) => {
          this.$dialog.error(err.msg);
        });
    },

    // 获取列表
    loadMore() {
      let that = this;

      if (that.loading) return; //阻止下次请求（false可以进行请求）；
      if (that.loadend) return; //阻止结束当前请求（false可以进行请求）；
      that.loading = true;

      let params = {
        uid: that.id,
        page: that.page,
        limit: that.limit,
      };
      getCommunityList(params).then((res) => {
        that.communityLsit.push.apply(that.communityLsit, res.data);
        that.loadend = res.data.length < that.limit; //判断所有数据是否加载完成；
        that.page = that.page + 1;
        that.initLoading = true;
        if (res.data.length > 0) {
          that.$nextTick(() => {
            that.calculateText();
          });
        } else {
          that.loading = false;
        }
      });
    },

    // 计算文字显示展开收起
    calculateText() {
      let dom = this.$refs.textContent;
      let page = (this.page - 2) * this.limit;
      for (let i = page; i < dom.length; i++) {
        // 获取文本一行高度
        let lineHeight = (window.innerWidth / 7.5) * 0.48;
        // 获取元素高度
        let curHeight = dom[i].offsetHeight;
        this.textHeight = `${lineHeight * 5}px`;
        if (curHeight > lineHeight * 5) {
          this.$set(
            this.communityLsit,
            i,
            Object.assign({}, this.communityLsit[i], { isShow: true })
          );
        } else {
          this.$set(
            this.communityLsit,
            i,
            Object.assign({}, this.communityLsit[i], { isShow: null })
          );
        }
      }
      this.loading = false;
    },

    // 发布发现
    myFind() {
      this.$router.push(`/user/MyFind`);
    },

    // 删除
    Delete(id) {
      MessageBox({
        title: "提示",
        message: "确定删除",
        showCancelButton: true,
      }).then((status) => {
        if (status === "confirm") {
          communityDelete(id)
            .then(() => {
              this.$dialog.success("删除成功");
              setTimeout(() => {
                this.$router.go(0);
              }, 1000);
            })
            .catch((err) => {
              this.$dialog.error(err.msg);
            });
        } else {
          return;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.community {
  .info {
    width: 100%;
    padding: 0.2rem 0.3rem;
    background: white;
    margin-bottom: 0.2rem;
    .top {
      display: flex;
      justify-content: space-between;
      img {
        width: 1.6rem;
        height: 1.6rem;
        border-radius: 100%;
        display: block;
      }
      .content {
        width: 5.3rem;
        padding-top: 0.1rem;
        ul li {
          float: left;
          width: 33.3333%;
          font-size: 0.24rem;
          text-align: center;
          span {
            font-size: 0.36rem;
            font-weight: 600;
          }
        }
        button {
          width: 1.6rem;
          height: 0.56rem;
          font: 0.24rem/0.56rem "microsoft yahei";
          margin-top: 0.3rem;
          float: right;
        }
      }
    }
    p {
      padding: 0.2rem 0;
    }
  }

  .container {
    padding: 0.4rem 0 0.8rem 1.2rem;
    background: white;
    .figure-list {
      margin: 0;
      padding: 0;
    }
    .one {
      img {
        width: 3.6rem;
        height: 3.6rem;
        object-fit: cover;
      }
    }
    .two {
      .img-container {
        display: flex;
        flex-flow: row wrap;
        justify-content: start;
        margin: 0 -0.05rem;
        img {
          width: 2.4rem;
          height: 2.4rem;
          margin: 0.05rem;
          object-fit: cover;
        }
      }
    }
    .three {
      .img-container {
        display: flex;
        flex-flow: row wrap;
        margin: 0 -0.05rem;
        img {
          width: 1.9rem;
          height: 1.9rem;
          margin: 0.05rem;
          object-fit: cover;
        }
      }
    }
    .video {
      position: relative;
      width: 4rem;
      height: 2.8rem;
      overflow: hidden;
      i {
        position: absolute;
        color: white;
        font-size: 0.6rem;
        z-index: 999;
        top: 1.7rem;
        left: 2.75rem;
      }
    }

    .title {
      height: 0.72rem;
      position: relative;
      a {
        width: 100%;
        position: relative;
        display: block;
        img {
          width: 0.72rem;
          height: 0.72rem;
          border-radius: 100%;
          position: absolute;
          left: -0.9rem;
        }
        p {
          display: flex;
          flex-flow: column nowrap;
          strong {
            font-size: 0.28rem;
          }
          span {
            margin-top: 0.04rem;
            font-size: 0.2rem;
            color: #999;
          }
        }
      }
      .share {
        position: absolute;
        top: 0.3rem;
        right: 0;
        font-size: 0.26rem;
        color: #aaa;
        border: 1px solid #f1f1f1;
        border-right: none;
        padding: 0.08rem 0.24rem;
        border-radius: 0.3rem 0 0 0.3rem;
        i {
          font-size: 0.2rem;
          margin-right: 0.1rem;
        }
      }
    }
    .content {
      font-size: 0.28rem;
      line-height: 0.48rem;
      margin: 0.4rem 0;
      letter-spacing: 0.02rem;
      padding: 0 0.4rem 0 0;
      p {
        overflow: hidden;
        margin-bottom: 0.1rem;
        white-space: pre-wrap;
      }
      span {
        color: #daa070;
        cursor: pointer;
      }
    }
    .bottom {
      width: 100%;
      font-size: 0.24rem;
      color: #999;
      line-height: 0.52rem;
      float: right;
      padding-right: 0.4rem;
      display: flex;
      justify-content: space-between;
      a {
        color: #999;
      }
      i {
        margin-right: 0.06rem;
      }
    }
  }
}
</style>
